import { CognitoUserInterface } from '@aws-amplify/ui-components'
import { CognitoDevice } from 'LeadsBridgeApp'
import { Auth } from 'aws-amplify'
import Cookies from 'universal-cookie'
import { PersistentCookiesKeys } from '@app/enums/persistentCookiesKeys'
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js'

export interface HandleSignupCognitoProps {
  username: string
  idToken: string
  accessToken: string
  refreshToken: string
}

export const signInUserSessionCognito = ({
  username,
  idToken,
  accessToken,
  refreshToken,
}: HandleSignupCognitoProps) => {
  const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_USER_POOL_ID || '',
    ClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '',
  })
  const cognitoIdToken = new CognitoIdToken({
    IdToken: idToken,
  })
  const cognitoAccessToken = new CognitoAccessToken({
    AccessToken: accessToken,
  })
  const cognitoRefreshToken = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  })

  const user = new CognitoUser({
    Username: username,
    Pool: userPool,
  })

  user.setSignInUserSession(
    new CognitoUserSession({
      AccessToken: cognitoAccessToken,
      IdToken: cognitoIdToken,
      RefreshToken: cognitoRefreshToken,
    })
  )

  user.getCachedDeviceKeyAndPassword()
}

export const getUserDevice = (user: CognitoUserInterface): Promise<string> => {
  return new Promise((resolve, reject) => {
    user.getDevice({
      onSuccess: (deviceKey: CognitoDevice) => {
        resolve(deviceKey.Device?.DeviceKey || '')
      },
      onFailure: (error?: string) => {
        reject(error || '')
      },
    })
  })
}

type CognitoSessionData = {
  accessToken: string
  deviceKey: string
}

// allow to cache cognito accessToken and deviceKey
export const fetchAndPersistCognitoSession = async () => {
  // getting cognito session
  const cognitoSession = await Auth.currentSession().catch((error) => {
    console.warn(`'Cannot authenticate the request: ${error}`)
  })

  if (!cognitoSession) {
    return
  }

  const expirationSec = cognitoSession.getIdToken().getExpiration()
  const accessToken = cognitoSession.getAccessToken().getJwtToken()

  // getting user device
  const user: CognitoUserInterface = await Auth.currentAuthenticatedUser()
  await user.getCachedDeviceKeyAndPassword()
  const deviceKey = '' // await getUserDevice(user)

  // preparing data for cookie storage
  const cookies = new Cookies()
  const congnitoSessionData: CognitoSessionData = {
    accessToken,
    deviceKey,
  }
  // setting cookie expiration to match cognito session expiration
  const cookieExpirationSecs = expirationSec - new Date().getTime() / 1000
  cookies.set(PersistentCookiesKeys.CognitoSession, congnitoSessionData, {
    path: '/',
    domain: process.env.REACT_APP_V1_COOKIE_DOMAIN,
    secure: true,
    sameSite: 'none',
    maxAge: cookieExpirationSecs,
  })

  return congnitoSessionData
}

export const getCognitoPersistentSession = () => {
  const cookies = new Cookies()
  const congnitoSessionData = cookies.get(
    PersistentCookiesKeys.CognitoSession
  ) as CognitoSessionData

  const isValidData =
    congnitoSessionData &&
    'deviceKey' in congnitoSessionData &&
    'accessToken' in congnitoSessionData

  return isValidData ? congnitoSessionData : null
}
